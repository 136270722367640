import React from "react";
import "./Loader.css";
import { Blocks } from "react-loader-spinner";

function Loader() {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center loader w-100 h-100">
        <div className="loaderImg position-absolute">
          <img src="/images/Loader.gif" alt="Image" />
          {/* <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperclassName="blocks-wrapper"
          /> */}
        </div>
      </div>
    </>
  );
}

export default Loader;
