import React from 'react'
import AdminLogin from '../../../component/Form/Admin/AdminLogin';
// import { AdminLogin } from '../../../component';

function AdminLoginPage() {
    return (
      <>
        <AdminLogin />
      </>
    );
  }
  
export default AdminLoginPage;

