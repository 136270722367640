import React, { lazy } from 'react'

export const AdminDashboard = React.lazy(() => import('./DashboardMain'))
export const AdminUserDetails = React.lazy(() => import('./DashboardMain/UserDetails'))
// export const VolumeView = React.lazy(() => import('./VolumeView'))
// export const FinancialView = React.lazy(() => import('./FinalcialView'))
export const AdminInvoiceDashboard = React.lazy(() => import('./Invoice'))
export const AdminVendor = React.lazy(() => import('./Vendors'))
export const AdminVendorDetails = React.lazy(() => import('./Vendors/VendorDetails'))
export const AdminVendorMarket = React.lazy(() => import('./Vendors/Market'))
export const AdminConfiguration = React.lazy(() => import('./Configuration'))
export const AdminRoles = React.lazy(() => import('./Roles'))
export const AdminAddRoles = React.lazy(() => import('./Roles/AddRoles'))
export const AdminEditRoles = React.lazy(() => import('./Roles/EditRole'))
export const AdminUsers = React.lazy(() => import('./Users'))
export const SubAdmin = React.lazy(() => import('./SubAdmin'))
export const AdminInvoice = React.lazy(() => import('./Invoice'))
export const AdminArchiveInvoice = React.lazy(() => import('./Archive'))
export const AdminEmailConfiguration = React.lazy(() => import('./EmailConfiguration'))
export const AdminReports = React.lazy(() => import('./Reports'))
export const AdminInvoiceLogs = React.lazy(() => import('./Reports/AdminInvoiceLogs'))
export const AdminTrashInvoiceLogs = React.lazy(() => import('./Reports/AdminTrashInvoiceLogs'))
