import React, { useState } from "react";
import { DashboardHeader } from "../..";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  FileUploadApi,
  InvoiceUploadApi,
} from "../../../../../../services/Api";
import { toast } from "react-toastify";
import CommonModal from "../../../../../../utils/Modal/CommonModal";
import { ImageName } from "../../../../../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../../page/User/Loader/Loader";

function UploadHeader() {
  const navigate = useNavigate();
  const [uploadBtn, setUploadBtn] = useState(true);
  const [continueBtn, setContinueBtn] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [filePaths, setFilePaths] = useState();
  const [loaderFlag, setLoaderFlag] = useState(false);
  const [loaderDataFlag, setLoaderDataFlag] = useState(true);

  const handleModalClose = () => {
    setModalOpen(false);
    setUploadBtn(true);
  };

  const handleUploadInvoice = () => {
    setContinueBtn(false);
    setLoaderFlag(true);
    let datas = {
      file_path: filePaths,
    };
    // console.log("datas = ", datas);
    // navigate('/invoice-details')
    InvoiceUploadApi(datas)
      .then((res) => {
        // console.log("res = ", res);
        if (res.data.status) {
          toast.success("File added successfully");
          setContinueBtn(true);
          setLoaderFlag(false);
          handleModalClose();
          navigate(`/invoice-details/${res?.data?.result?.id}`);
        }
      })
      .catch((error) => {
        setContinueBtn(true);
        setLoaderFlag(false);
        console.log({ Message: "Api Not Working", Error: error });
      });
  };

  const { Dragger } = Upload;
  // const props = {
  //   name: "file",
  //   multiple: true,
  //   action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  //   onChange(info) {
  //     console.log("info.file = ", info.file);
  //     const { status } = info.file;
  //     if (status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   onDrop(e) {
  //     console.log("Dropped files", e.dataTransfer.files);
  //   },
  // };

  return (
    <>
      <DashboardHeader title="Invoice Upload" switchStatus={false} />
      {/* Drag & Drop Functionality */}
      <div className="bg-white p-3 p-md-4 bigRadius h-100 m-3 m-md-4">
        <h2 className="mb-4">Upload your Invoice </h2>
        <Formik
          enableReinitialize
          initialValues={{
            file: "",
          }}
          validationSchema={Yup.object({
            file: Yup.mixed().required("Please upload pdf file"),
          })}
          onSubmit={(values) => {
            setUploadBtn(false);
            setFilePaths(values.file);
            // console.log("file paths = ", values.file);
            // let pthImg = ImageName(values.file)
            // console.log('pthImg = ',pthImg)
            setModalOpen(true);
            // let data = {
            //   file: values.file,
            // }
            // FileUploadApi(data)
            //   .then((res) => {
            //     console.log("res = ", res);
            //     if (res.data.status) {
            //       console.log('file res = ',res)
            //     }
            //   })
            //   .catch((error) => {
            //     console.log({ Message: "Api Not Working", Error: error });
            //   });
          }}
        >
          {(props) => {
            return (
              <Form encType="multipart/form-data">
                <Dragger
                  name="file"
                  multiple={false}
                  action={`${process.env.REACT_APP_APIURL}upload-invoice-file`}
                  maxCount={1}
                  beforeUpload={(file) => {
                    // Check if the file is a PDF or CSV
                    // console.log("before uploading file = ", file);
                    const isPDF = file.type === "application/pdf";
                    // const isCSV = file.type === 'text/csv' || file.name.endsWith('.csv');

                    if (!isPDF) {
                      // message.error('Only PDF files can be uploaded.');
                      // console.log({
                      //   Message: "Only PDF files can be uploaded.",
                      // });
                      props.setFieldTouched("file", true);
                      props.setFieldError(
                        "file",
                        "Only PDF files can be uploaded."
                      );
                    }

                    return isPDF;
                  }}
                  onChange={(info) => {
                    // console.log("onchange file = ", info.file);
                    const { status, response } = info.file;
                    if (status !== "uploading") {
                    }

                    if (status === "done") {
                      // console.log("response?.result = ", response?.result);
                      // FileUploadApi(data)
                      // .then((res) => {
                      //   console.log("res = ", res);
                      //   if (res.data.status) {
                      //     console.log('file res = ',res)
                      //   }
                      // })
                      // .catch((error) => {
                      //   console.log({ Message: "Api Not Working", Error: error });
                      // });
                      props.setFieldValue("file", response?.result);
                      if (
                        info.file.type === "application/pdf" &&
                        info.file.name.endsWith(".pdf")
                      ) {
                        message.success(
                          `${info.file.name} file uploaded successfully.`
                        );
                      } else {
                        message.error("Only PDF files can be uploaded.");
                      }
                    } else if (status === "error" || !status) {
                      message.error(`${info.file.name} file upload failed.`);
                      console.log(
                        "Error = ",
                        `${info.file.name} file upload failed.`
                      );
                    }
                  }}
                  onDrop={(e) => {
                    console.log("Dropped files", e.dataTransfer.files);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    {/* <InboxOutlined /> */}
                    {/* <img src="/images/pdfUpload.svg" /> */}
                    <img src="/images/PDF-Icons.svg" />
                  </p>
                  <p className="ant-upload-text fs-5 mb-2">
                    Drag & Drop files or <span className="browse">Browse</span>
                  </p>
                  <p className="ant-upload-hint fs-6 w-50 mx-auto">
                    Supported formats: pdf Maximum file size 50MB
                  </p>
                </Dragger>

                {props.touched?.file && props.errors?.file && (
                  <small style={{ color: "red" }} className="error">
                    {props.errors?.file}
                  </small>
                )}

                <div className="text-end mt-4">
                  <Button
                    type="submit"
                    className="btn pdfUploadBtn px-5 py-3 text-white rounded-pill"
                    disabled={!uploadBtn}
                  >
                    Upload
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      {/* Preview Invoice Modal */}
      <CommonModal
        show={modalOpen}
        handleClose={handleModalClose}
        title="Invoice Preview"
      >
        <Modal.Body className="addContract">
          <div className="p-2 invoicePreview">
            <iframe
              src={filePaths}
              // src="https://research.google.com/pubs/archive/44678.pdf#toolbar=0"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            ></iframe>
          </div>
          <div className="text-end">
            <button
              disabled={!continueBtn}
              onClick={handleUploadInvoice}
              className="btn pdfUploadBtn px-4 py-2 text-white rounded-pill"
            >
              Save & Continue
            </button>
          </div>
          {loaderFlag && loaderDataFlag ? <Loader /> : ""}
        </Modal.Body>
      </CommonModal>
    </>
  );
}

export default UploadHeader;
