import React from "react";

function InvoiceTimelineCard({ ...props}) {
  return (
    <>
        <div className="mb-3 px-2">
              <div className={`${props.color} px-2 py-3 rounded`}>
                  <h5>{ props.name }</h5>
                  <p className="mb-0">{ props.date }</p>
            </div>
        </div>
    </>
  );
}

export default InvoiceTimelineCard;
