import React, {useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function Protected({ children }) {
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let token = localStorage.getItem("Login");
  useEffect(() => {
    if (!token) {
      navigate(`/?path=${location?.pathname?.replace("/", "")}`);
    } else { 
      setIsValid(true);
    }
  }, [token]);

  return <>{isValid && children}</>;
}

export default Protected;
