import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Outlet, Routes } from "react-router-dom";
import { Suspense } from "react";
import {
  PrivateLayout,
  PublicLayout,
  LandingPage,
  Terms,
  LandingAdminPage,
} from "../Layout";
import {
  Login,
  SignUp,
  Dashboard,
  VolumeView,
  InvoiceDashboard,
  Vendor,
  UserDetails,
  Configuration,
  VendorDetails,
  VendorMarket,
  Roles,
  AddRoles,
  Users,
  Archive,
  EmailConfiguration,
  PaymentPlanner,
  PaymentPlannerDetails,
  TwoWayAuthComponent,
  TwoWayQrCode,
  OTP,
  AuthDisable,
  AuthTwoWay,
  Report,
  InvoiceStatus,
  VendorReport,
  Category,
  GeneralSetting,
  ContactSupport,
  VendorApprover,
  AmountApprover,
  UserPermissions,
  InvoiceLogs,
  EditInvoiceLogs,
  TraceInvoiceLogs,
} from "../page";
import {
  ForgotPassword,
  NewPassword,
  EmailVerified,
  PrivacyPolicy,
  TermsAndCondiction,
  CustomerCare,
  Calenderes,
  AdminLogin,
  ThankYou,
} from "../component";
import Protected from "./protected";
import UploadHeader from "../component/UiElement/User/Dashboard/Header/UploadHeader";
import { Notify } from "../component/UiElement/User/Dashboard/Header/Notification";
import AdminLayout from "../Layout/index.admin";
import AdminLoginPage from "../page/Admin/AdminLogin";
import EditRoles from "../page/User/Dashboard/Roles/EditRole";
import Loader from "../page/User/Loader/Loader";
import {
  AdminAddRoles,
  AdminDashboard,
  AdminEditRoles,
  AdminRoles,
  AdminUsers,
  AdminVendor,
  SubAdmin,
  AdminConfiguration,
  AdminVendorDetails,
  AdminInvoice,
  AdminUserDetails,
  AdminArchiveInvoice,
  AdminEmailConfiguration,
  AdminReports,
  AdminInvoiceLogs,
  AdminTrashInvoiceLogs,
} from "../page/Admin";
import { AdminNotify } from "../component/UiElement/Admin/AdminHeader/Notification";
import { AdminCalenderes } from "../component/UiElement/Admin/AdminHeader/Calenderes";
import AdminTraceInvoiceLogs from "../page/Admin/AdminDashboard/Reports/AdminTrashInvoiceLogs";
// import AdminTraceInvoiceLogs from "../page/Admin/AdminDashboard/Reports/AdminTrashInvoiceLogs";

function Pages() {
  return (
    <>
      <Suspense
        fallback={
          <>
            <div>
              <Loader />
            </div>
          </>
        }
      >
        <Routes>
          <Route exact path="/" name="Landing" element={<LandingPage />} />
          <Route exact path="/thank-you" name="Thank You" element={<ThankYou />} />
          <Route
            exact
            path="/terms"
            name="Terms&Condition"
            element={<TermsAndCondiction />}
          />
          <Route
            exact
            path="/privacy"
            name="Privacy Policy"
            element={<PrivacyPolicy />}
          />
          <Route
            exact
            path="/care"
            name="Customer Care"
            element={<CustomerCare />}
          />
          <Route exact path="/explore" name="Explorer" element={<Terms />} />
          <Route exact path="/more" name="More" element={<Terms />} />

          {/* PublicLayout */}
          <Route element={<PublicLayout />}>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/signup"
              name="Signup Page"
              element={<SignUp />}
            />
            <Route
              exact
              path="/forgotpassword"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/createpassword/:token"
              name="Create New Password"
              element={<NewPassword />}
            />
            <Route
              exact
              path="/activation/:token"
              name="Email Verify"
              element={<EmailVerified />}
            />
            <Route
              exact
              path="/two-way-auth"
              name="Two Way Enabled"
              element={<AuthTwoWay />}
            />
            <Route
              exact
              path="/two-way-auth-qrcode"
              name="Two Way QR Code"
              element={<TwoWayQrCode />}
            />
            <Route
              exact
              path="/two-way-auth-otp"
              name="Two Way OTP"
              element={<OTP />}
            />
            {/* <Route exact path="/twowayauth" name="Two Way Auth" element={<TwoWayAuth />} /> */}
          </Route>

          {/* PrivateLayout */}
          <Route element={<PrivateLayout />}>
            <Route
              exact
              path="/dashboard"
              name="Dashboard"
              element={<Dashboard />}
            />
            <Route
              exact
              path="/calenderes"
              name="Calenderes"
              element={<Calenderes />}
            />
            <Route
              exact
              path="/configuration"
              name="Configuration"
              element={<Configuration />}
            />
            <Route
              exact
              path="/notification"
              name="Notify"
              element={<Notify />}
            />
            <Route
              exact
              path="/upload-invoice"
              name="Upload Header"
              element={<UploadHeader />}
            />
            <Route exact path="/vendors" name="Vendors" element={<Vendor />} />
            <Route
              exact
              path="/vendors-details/:id"
              name="Vendors Details"
              element={<VendorDetails />}
            />
            <Route
              exact
              path="/vendors-details/:id/edit"
              name="Vendors Details"
              element={<VendorDetails />}
            />
            <Route
              exact
              path="/market"
              name="Market"
              element={<VendorMarket />}
            />
            <Route exact path="/roles" name="Roles" element={<Roles />} />
            <Route
              exact
              path="/add-roles"
              name="Add Roles"
              element={<AddRoles />}
            />
            <Route
              exact
              path="/edit-roles/:id"
              name="Edit Roles"
              element={<EditRoles />}
            />
            <Route
              exact
              path="/vendor-approver"
              name="Vendor Approver"
              element={<VendorApprover />}
            />
            <Route
              exact
              path="/amount-approver"
              name="Amount Approver"
              element={<AmountApprover />}
            />
            <Route exact path="/user" name="Users" element={<Users />} />
            <Route
              exact
              path="/invoice-details/:id"
              name="Invoice Details"
              element={<UserDetails />}
            />
            <Route
              exact
              path="/invoice-timeline"
              name="invoice-timeline"
              element={<InvoiceDashboard />}
            />
            <Route exact path="/archive" name="Archive" element={<Archive />} />
            <Route
              exact
              path="/email-configuration"
              name="Archive"
              element={<EmailConfiguration />}
            />
            <Route
              exact
              path="/category"
              name="Category"
              element={<Category />}
            />
            <Route
              exact
              path="/general-setting"
              name="General Setting"
              element={<GeneralSetting />}
            />
            <Route
              exact
              path="/contact-support"
              name="Contact Support"
              element={<ContactSupport />}
            />
            <Route
              exact
              path="/payment-planner"
              name="PaymentPlanner"
              element={<PaymentPlanner />}
            />
            <Route
              exact
              path="/payment-planner-details/:id"
              name="PaymentPlannerDetails"
              element={<PaymentPlannerDetails />}
            />
            <Route
              exact
              path="/two-way-enables"
              name="Two Way Enabled"
              element={<TwoWayAuthComponent />}
            />
            <Route
              exact
              path="/two-way-qrcode"
              name="Two Way QR Code"
              element={<TwoWayQrCode />}
            />
            <Route
              exact
              path="/two-way-otp"
              name="Two Way OTP"
              element={<OTP />}
            />
            <Route
              exact
              path="/two-way-auth-disable"
              name="Two Way AuthDisable"
              element={<AuthDisable />}
            />
            <Route
              exact
              path="/invoice-reports"
              name="Invoice Reports"
              element={<Report />}
            />
            <Route
              exact
              path="/vendor-reports"
              name="Invoice Reports"
              element={<VendorReport />}
            />
            <Route
              exact
              path="/invoice-reports-status"
              name="Invoice Reports"
              element={<InvoiceStatus />}
            />
            <Route
              exact
              path="/permission-reports"
              name="User Permissions Reports"
              element={<UserPermissions />}
            />
            <Route
              exact
              path="/invoice-logs"
              name="Invoice Logs"
              element={<InvoiceLogs />}
            />
            <Route
              exact
              path="/edit-invoice-logs/:id"
              name="Edit Invoice Logs"
              element={<EditInvoiceLogs />}
            />
            <Route
              exact
              path="/trash-logs"
              name="Trash Invoice Logs"
              element={<TraceInvoiceLogs />}
            />
          </Route>

          {/* AdminLayout Sections */}
          <Route exact path="/admin">
            <Route path="login" element={<AdminLoginPage />} />
            <Route element={<LandingAdminPage />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="vendors" element={<AdminVendor />} />
              <Route
                path="vendors-details/:id"
                element={<AdminVendorDetails />}
              />
              <Route
                path="vendors-details/:id/edit"
                element={<AdminVendorDetails />}
              />
              <Route path="user" element={<AdminUsers />} />
              <Route path="sub-admin" element={<SubAdmin />} />
              <Route path="roles" element={<AdminRoles />} />
              <Route path="add-roles" element={<AdminAddRoles />} />
              <Route path="configuration" element={<AdminConfiguration />} />
              <Route path="edit-roles/:id/:id" element={<AdminEditRoles />} />
              <Route path="notify" element={<AdminNotify />} />
              <Route
                path="calenderes"
                name="Calenderes"
                element={<AdminCalenderes />}
              />
              <Route path="invoice-timeline" element={<AdminInvoice />} />
              <Route
                path="invoice-details/:id"
                element={<AdminUserDetails />}
              />
              <Route path="archive" element={<AdminArchiveInvoice />} />
              <Route
                path="email-configuration"
                element={<AdminEmailConfiguration />}
              />
              <Route path="reports" element={<AdminReports />} />
              <Route path="invoice-logs" element={<AdminInvoiceLogs />} />
              <Route path="trash-logs" element={<AdminTraceInvoiceLogs />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default Pages;
