import React from 'react'

export const PublicLayout = React.lazy(() => import("./index.public"))
export const PrivateLayout = React.lazy(() => import("./index.private"))
export const LandingPage = React.lazy(() => import("./index.landing"))
export const LandingAdminPage = React.lazy(() => import("./index.admin"))

export const Terms = React.lazy(() => import("./index.terms"))
// export const Support = React.lazy(() => import("./index.support"))
// export const Care = React.lazy(() => import("./index.care"))
