import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Pagination } from "swiper/core";
import "./Swiper.css";
import { Autoplay, Pagination } from "swiper/modules";

function SwiperSliderComponent() {
  const slideContent = [
    {
      title: "A high-quality solution",
      description:
        "Paydesk AI is a white label NO-CODE solution designed to revolutionize the infrastructure needed to manage the Accounts Payable and procure to payment cycle.",
    },
    {
      title: "A high-quality solution",
      description:
        "Paydesk AI is a white label NO-CODE solution designed to revolutionize the infrastructure needed to manage the Accounts Payable and procure to payment cycle.",
    },
    {
      title: "A high-quality solution",
      description:
        "Paydesk AI is a white label NO-CODE solution designed to revolutionize the infrastructure needed to manage the Accounts Payable and procure to payment cycle.",
    },
    // Add more slide content as needed
  ];
  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        className="w-100"
        pagination={{ clickable: true }}
        modules={[Autoplay, Pagination]}
        // centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
      >
        {slideContent.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="authLeftContent p-2 py-xl-5">
              <h2 className="fw-bold text-white mb-4 mt-3">{slide.title}</h2>
              <p className="text-white">{slide.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default SwiperSliderComponent;
