import React, { useState } from "react";
import { Outlet } from "react-router-dom";
// import { SuperAdminSidebar } from "../component";
import "../CSS/global.css";
import "../CSS/global-component.css";
import "../CSS/style.css";
import AdminProtected from "../routes/AdminProtectde";
import { AdminSidebar } from "../component";
// import { AdminSidebar } from "../component";
import "../CSS/responsive.css";

function AdminLayout() {
  const [collapes, setCollpes] = useState(false);

  return (
    <>
      <AdminProtected>
        <section className="">
          <div
            className={`main d-flex align-items-start ${collapes ? " collapseSidebar" : "" }`} >
            {/* <div className="main d-flex align-items-start"> */}
            <AdminSidebar collapes={collapes} setCollpes={setCollpes} />
            <div className="rightbar grey ms-auto">
              {/* Child component Render in Outlet*/}
              <Outlet />    
            </div>
          </div>
        </section>
      </AdminProtected>
    </>
  );
}

export default AdminLayout;
