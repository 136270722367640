import React, { useState, useEffect } from "react";
import { DashboardHeader } from "../../../../component";
import { MyCheckbox, TextInput } from "../../../../utils/FormikInputField";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import {
  AddRoleApi,
  GetAllPermissionApi,
  GetRoleByIdApi,
  UpdateRoleApi,
} from "../../../../services/Api";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";

function EditRoles() {
  const { id } = useParams();
  const [permissionData, setPermissionData] = useState([]);
  const [errorPermission, setErrorPermission] = useState(false);
  const [fullData, setFullData] = useState();
  const [viewData, setViewData] = useState();
  const [loaderFlag, setLoaderFlag] = useState(false);
  const [loaderDataFlag, setLoaderDataFlag] = useState(true);
  const [updateRole, setUpdateRole] = useState(true);

  const goBack = () => {
    window.history.back();
  };

  const handleInvoiceModule = (e) => {
    // console.log("checked clicked = ", e.target.value);
    var value = e.target.value;
    if (permissionData?.includes(value)) {
      setPermissionData(permissionData.filter((item) => item !== value));
    } else {
      setPermissionData([...permissionData, value]);
    }
  };
  //   console.log("permissionData = ", permissionData);

  const loadUser = () => {
    setLoaderFlag(true);
    GetAllPermissionApi()
      .then((res) => {
        console.log("permission = ", res);
        setFullData(res.data?.result);
        setLoaderFlag(false);
      })
      .catch((err) => {
        setLoaderFlag(false);
        console.log({ Message: "Api is not working", Error: err });
      });

    GetRoleByIdApi(id)
      .then((res) => {
        console.log("edit permission = ", res);
        setViewData(res.data?.result);
        setPermissionData(res.data?.result?.permissions);
      })
      .catch((err) => {
        console.log({ Message: "Api is not working", Error: err });
      });
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <>
      <DashboardHeader title="Roles " switchStatus={false} />

      <div className="bg-white p-3 p-lg-4 bigRadius h-100 m-3 m-md-4 position-relative">
        <div className="pb-3 mb-3 border-bottom">
          <p className="cursorPointer d-inline-block mb-0" onClick={goBack}>
            <img src="/images/back.svg" /> Back
          </p>
        </div>
        <h4 className="fw-bold">Create Role</h4>

        <Formik
          enableReinitialize
          initialValues={{
            name: viewData?.name,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
          })}
          onSubmit={(values) => {
            if (permissionData.length > 0) {
              setUpdateRole(false);
              setErrorPermission(false);
              var data = {
                name: values?.name,
                permissions: permissionData,
                id: id,
              };
              console.log("data = ", data);
              UpdateRoleApi(data)
                .then((res) => {
                  if (res.data.status) {
                    toast.success("Role Updated Successfully");
                    setUpdateRole(true);
                    goBack();
                    // loadUser();
                    // window.location.reload()
                  }
                })
                .catch((error) => {
                  setUpdateRole(true);
                  console.log({ Message: "Api Not Working", Error: error });
                });
            } else {
              setErrorPermission(true);
            }
          }}
        >
          {(props) => {
            return (
              <Form encType="multipart/form-data">
                <div className="space position-relative mb-4 w-50 roleInput">
                  <label className="text-dark mb-2">Role Name</label>
                  <TextInput
                    type="text"
                    name="name"
                    label="name"
                    placeholder="Role Name"
                    className="form-control bg-light bigRadius"
                    onChange={props?.handleChange}
                    error={props.touched.name && Boolean(props.errors.name)}
                    touched={props.touched.name}
                    errors={props.errors.name}
                  />
                </div>

                <h4 className="fw-bold">Access Permissions</h4>
                {errorPermission ? (
                  <p style={{ color: "red" }}>
                    Please Select Atleast One Permission.
                  </p>
                ) : (
                  ""
                )}
                <div className="d-flex align-items-start flex-wrap my-4 accessPermission">
                  {fullData?.map((Item) => (
                    <div key={Item.key} className="form-check mb-3 w-50">
                      <input
                        className="form-check-input mt-0 me-1 me-md-3"
                        type="checkbox"
                        value={Item.key}
                        onClick={(e) => handleInvoiceModule(e)}
                        checked={permissionData.includes(Item.key)}
                      />
                      <label className="form-check-label fs-5">
                        {Item.name}
                      </label>
                    </div>
                  ))}
                </div>

                <hr />
                <div className="text-end">
                  <button
                    disabled={!updateRole}
                    className="addBtn py-3 px-5 rounded-pill border-0 text-white"
                    type="submit"
                  >
                    Role Update
                  </button>
                </div>
                {loaderFlag && loaderDataFlag ? <Loader /> : ""}
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default EditRoles;
