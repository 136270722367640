import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  getLocalStorageToken,
  setLocalStorageToken,
} from "../../../../utils/CommonFunction";
import "../../../../CSS/global.css";
import "../../../../CSS/global-component.css";
import "../../../../CSS/style.css";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../../../utils/FormikInputField";
import { Button } from "react-bootstrap";
import SwiperSliderComponent from "../../../UiElement/User/SliderComponent";
import { toast } from "react-toastify";
import {
  AdminGetRoleByIdApi,
  AdminLoginApi,
} from "../../../../services/AdminApi";
import { useDispatch } from "react-redux";
import { loginData } from "../../../../Redux/AuthSlices";
import "../../../../CSS/responsive.css";

function AdminLogin() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const handleVisible = () => {
    setVisible(!visible);
  };
  //   useEffect(() => {
  //     let token = getLocalStorageToken("Login");
  //     if (token) {
  //       navigate("/dashboard");
  //     }
  //   }, []);

  return (
    <>
      <section className="authPage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 gx-0">
              <div className="authLeftSide d-flex align-items-start justify-content-center">
                <div className="px-3 py-5 p-lg-5 w-100">
                  <div className="authlogo p-2 pt-md-5 px-lg-0 py-lg-4 text-center">
                    <img
                      src="/images/logo.svg"
                      alt=""
                      className="w-75 mx-auto"
                    />
                  </div>
                  <div className="authLeftContent p-2 py-xl-5">
                    <SwiperSliderComponent />
                  </div>
                </div>
              </div>
            </div>
            {/* Login */}

            <Formik
              enableReinitialize
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  // .email("Invalid email address")
                  .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    "Invalid email address"
                  )
                  .required("Email is required"),
                password: Yup.string()
                  // .min(8, "Password must be at least 8 characters")
                  .required("Password is required"),
              })}
              onSubmit={async (values) => {
                try {
                  var data = {
                    email: values.email,
                    password: values.password,
                  };
                  var res = await AdminLoginApi(data);
                  // console.log("login data = ", res);
                  setLocalStorageToken("AdminLogin", res.data.result.authToken);
                  dispatch(loginData(res?.data?.result));
                  let CheckLogin = getLocalStorageToken("AdminLogin");
                  if (CheckLogin) {
                    var permissions = [];
                    // console.log(
                    //   "Admin res?.data?.result = = ",
                    //   res?.data?.result
                    // );
                    if (
                      res?.data?.result?.role_id != "fullaccess" &&
                      res?.data?.result?.role_id
                    ) {
                      await AdminGetRoleByIdApi(res?.data?.result?.role_id)
                        .then((res) => {
                          // console.log("Role By Id = ", res);
                          permissions = [
                            ...permissions,
                            ...(res?.data?.result?.permissions
                              ? res?.data?.result?.permissions
                              : []),
                          ];
                          localStorage.setItem("AdminPermission", permissions);
                        })
                        .catch((err) => {
                          console.log({
                            Message: "Api is not working.",
                            Error: err,
                          });
                        });
                    } else if (res?.data?.result?.role_id == "fullaccess") {
                      permissions = ["fullaccess"];
                      localStorage.setItem("AdminPermission", permissions);
                    }

                    toast.success("Login Successfully");
                    if (location?.search) {
                      let data = Object.fromEntries(
                        new URLSearchParams(location?.search)
                      );
                      navigate(`/admin/${data?.path}`);
                    } else {
                      navigate("/admin/dashboard");
                    }
                  }
                } catch (error) {
                  console.log({ Message: "Data Not Found.", Error: error });
                }
              }}
            >
              {(props) => {
                return (
                  <>
                    <div className="col-12 col-md-6">
                      <div className="authFormContainer">
                        <Form
                          encType="multipart/form-data"
                          className="row gx-0 my-5 px-2 px-md-4 px-xl-5 mx-2 mx-md-4 mx-xl-5"
                        >
                          <div className="mb-4">
                            <p className="fs-5 mb-0">Welcome.!</p>
                            <h3 className="fw-bold">Log In to your Account</h3>
                          </div>

                          <MyTextInput
                            type="text"
                            name="email"
                            label="Email"
                            placeholder="johnsondoe@nomail.com"
                            id="email"
                            className="bigRadius"
                            variant="outlined"
                            onChange={props?.handleChange}
                            error={
                              props.touched.email && Boolean(props.errors.email)
                            }
                            touched={props.touched.email}
                            errors={props.errors.email}
                            fullWidth
                          />

                          <div className="material-textfield position-relative p-0 mb-3">
                            {/* <label>Email</label> */}
                          </div>
                          <div className="position-relative">
                            <MyTextInput
                              type={visible ? "text" : "password"}
                              name="password"
                              label="Password"
                              placeholder="************"
                              id="password"
                              variant="outlined"
                              className="bigRadius"
                              onChange={props?.handleChange}
                              error={
                                props.touched.password &&
                                Boolean(props.errors.password)
                              }
                              touched={props.touched.password}
                              errors={props.errors.password}
                              fullWidth
                            />
                            {visible ? (
                              <span className="eye position-absolute">
                                <img
                                  src="/images/eye-open.svg"
                                  onClick={handleVisible}
                                />
                              </span>
                            ) : (
                              <span className="eye position-absolute">
                                <img
                                  src="/images/eye-close.svg"
                                  onClick={handleVisible}
                                />
                              </span>
                            )}
                          </div>

                          <div className="material-textfield position-relative p-0 mb-3"></div>

                          <Button
                            type="submit"
                            className="btn btn-primary py-3 w-100 mt-4 rounded-pill"
                          >
                            Continue
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminLogin;
