import React, { lazy } from 'react'

export const Dashboard = React.lazy(() => import('./DashboardMain'))
export const UserDetails = React.lazy(() => import('./DashboardMain/UserDetails'))
// export const VolumeView = React.lazy(() => import('./VolumeView'))
// export const FinancialView = React.lazy(() => import('./FinalcialView'))
export const InvoiceDashboard = React.lazy(() => import('./Invoice'))
export const Vendor = React.lazy(() => import('./Vendors'))
export const VendorDetails = React.lazy(() => import('./Vendors/VendorDetails'))
export const VendorMarket = React.lazy(() => import('./Vendors/Market'))
export const Configuration = React.lazy(() => import('./Configuration'))
export const Roles = React.lazy(() => import('./Roles'))
export const AddRoles = React.lazy(() => import('./Roles/AddRoles'))
export const EditRoles = React.lazy(() => import('./Roles/EditRole'))
export const VendorApprover = React.lazy(() => import('./Roles/VendorApprover'))
export const AmountApprover = React.lazy(() => import('./Roles/AmountApprover'))
export const Users = React.lazy(() => import('./Users'))
export const Archive = React.lazy(() => import('./Archive'))
export const EmailConfiguration = React.lazy(() => import('./EmailConfiguration'))
export const PaymentPlanner = React.lazy(() => import('./PaymentPlanner'))
export const Category = React.lazy(() => import('./Category'))
export const GeneralSetting = React.lazy(() => import('./GeneralSetting'))
export const ContactSupport = React.lazy(() => import('./ContactSupport'))
export const PaymentPlannerDetails = React.lazy(() => import('./PaymentPlanner/PaymentPlannerDetails'))
export const AuthTwoWay = React.lazy(() => import("./AuthTwoWay"));
export const TwoWayAuthComponent = React.lazy(() => import("./TwoWayAuthComponent"));
export const TwoWayQrCode = React.lazy(() => import("./TwoWayAuthComponent/QrCode"));
export const OTP = React.lazy(() => import("./TwoWayAuthComponent/Otp"));
export const AuthDisable = React.lazy(() => import("./TwoWayAuthComponent/AuthDisable"));

export const Report = React.lazy(() => import("./Reports"));
export const InvoiceStatus = React.lazy(() => import("./Reports/InvoiceStatus"));
export const VendorReport = React.lazy(() => import("./Reports/VendorReport"));
export const UserPermissions = React.lazy(() => import("./Reports/UserPermissions"));
export const InvoiceLogs = React.lazy(() => import("./Reports/InvoiceLogs"));
export const EditInvoiceLogs = React.lazy(() => import("./Reports/EditInvoiceLogs"));
export const TraceInvoiceLogs = React.lazy(() => import("./Reports/TraceInvoiceLogs"));
