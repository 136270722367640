import React from "react";

export const LoginForm = React.lazy(() => import("./LoginForm"));
export const SignUpForm = React.lazy(() => import("./SignUpForm"));
export const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
export const NewPassword = React.lazy(() => import("./NewPassword"));
export const AuthFooter = React.lazy(() => import("./AuthFooter"));
export const EmailVerified = React.lazy(() => import("./EmailVerified"));
export const CustomerCare = React.lazy(() => import("./AuthFooter/CustomerCare") );
export const TermsAndCondiction = React.lazy(() => import("./AuthFooter/TermsAndCondiction") );
export const PrivacyPolicy = React.lazy(() => import("./AuthFooter/PrivacyPolicy"));
export const TwoWayAuth = React.lazy(() => import("./TwoWayAuth"));
