import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function AdminProtected({ children }) {
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let token = localStorage.getItem("AdminLogin");
  useEffect(() => {
    // console.log(!token);
    if (!token) {
      // navigate(`/?path=${location?.pathname.replace("admin/login", "")}`);
      navigate("/admin/login");
    } else {
      setIsValid(true);
    }
  }, [token]);

  return <>{isValid && children}</>;
}

export default AdminProtected;
